<template>
    <div class="main main-scroll btm-menu">
        <div tabindex="-1" class="main-sidebar">
            <div class="main-sidebar__head">
                <h4 class="title">ANGEBOTE</h4>
                <p>Veranstaltungen, Kurse, Workshops und mehr</p>
            </div>

            <div class="main-sidebar__content none" v-if="expandFilter && !$isMobileScreen && tempShowHide">
                <keyword-search class="mb20" :initialValue="dataSet.query.meilisearch"
                    @searchWordChanged="searchWordChanged" ref="searchBox" />

                <h6 class="fw400 mb-1">Suchzeitraum</h6>

                <custom-date-picker :initialValue="dateTimeSelection.selectedStartDate" label="Start-Datum wählen"
                    @changedDate="startDateChanged" />

                <custom-date-picker :initialValue="dateTimeSelection.selectedEndDate"
                    label="End-Datum wählen (optional)" @changedDate="endDateChanged" />

                <time-picker customClass="mt30 pb10" :initialValue="dateTimeSelection.selectedStartTime"
                    label="Start-Uhrzeit ab" @changedTime="startTimeChanged" />

                <time-picker :initialValue="dateTimeSelection.selectedEndTime" label="End-Uhrzeit bis (optional)"
                    @changedTime="endTimeChanged" />

                <h6 class="fw400 mb-1 mt30">Filter</h6>

                <MainCategorySelect class="mb15" v-if="mainBasicFilter !== null" name="Kategorie"
                    :name="mainBasicFilter.label" :field-type-id="mainBasicFilter.field_type_id"
                    :values="mainBasicFilter.options" :selectedFilters="selectedFilters" @on-select="filterChanged" />

                <WBSelectField v-if="basicFilters !== null" v-for="(item, index) in basicFilters"
                    :key="item.field_type_id" class="mb15" :name="item.label" :field-type-id="item.field_type_id"
                    :values="item.options" :selectedFilters="selectedFilters" :isMultiple="true"
                    @on-select="filterChanged" />

                <toggle-switch fieldTypeId="price" @sliderChanged="sliderChanged" />

                <button class="btn btn-link text-primary mb20" @click="resetAllFilters">Filter zurücksetzen</button>
                <button class="btn btn-link float-right text-primary mb20" @click="filterModal = true">Mehr
                    Filter</button>

                <div class="clearfix"></div>

            </div>
        </div>

        <div tabindex="-1" class="main-content" id="main-scroll-container">
            <TitleBar v-if="contentType !== null && $isMobileScreen" karteLink="/orte" :filters="allFilters"
                :selectedFilters="selectedFilters" :showTermin="true" listeLink="/orte?show=liste"
                :showMapOrList="showMap" :isFixed="true" :contentCount="tempContentCount"
                :initialKeywordSearchValue="dataSet.query.meilisearch" @contentFilteringFinished="filteringFinished"
                :dateTimeSelection="dateTimeSelection" @resetAllFilters="resetAllFilters"
                @getNumberOfResults="getNumberOfResults" @searchWordChanged="searchWordChanged"
                @changedTimeFilters="timeFilterChanged" />

            <div class="section">
                <div class="container-fluid" v-if="dataSet.total > 0">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="mb20">{{ dataSet.total }} Ergebnisse</h5>
                        </div>
                    </div>
                    <div class="offer-row row" v-if="dataSet.data !== null && dataSet.data.length > 0">
                        <div class="col-12 col-md-6 col-lgs-6 col-lg-6 col-xls-6 col-xl-4 d-flex align-items-stretch"
                            v-for="(item, index) in dataSet.data" :key="index">
                            <angebot-card :item="item" :noslider="true" />
                        </div>
                    </div>
                    <pagination v-if="dataSet.total > 12" break-view-link-class="break-style"
                        :page-count="dataSet.meta.last_page" :click-handler="changePage" containerClass="pagination"
                        prev-text="&laquo" next-text="&raquo" />
                </div>
                <div v-else-if="!searching" class="search-no-results">
                    <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                    <h5 class="mt20">Keine Ergebnisse gefunden</h5>
                </div>
            </div>
        </div>

        <main-category-select-modal @filterChanged="filterAndFocusChanged" :filterId="parseInt(345)"
            v-if="showMainCategorySelectModal && mainBasicFilter !== null" />

        <StructuredMoreFilters :showModal="filterModal" @on-close="updateOnClose" :contentCount="tempContentCount"
            :selectedFilters="selectedFilters" @getNumberOfResults="getNumberOfResults"
            :structuredFilters="structuredFilters" v-if="filterModal && structuredFilters !== null && !isMobileView"
            @filteringFinished="filteringFinished" />
    </div>
</template>

<script>
import Resource from '@/api/resource';
const contentTypeResource = new Resource('contenttypes');
import { getSublocalities } from '@/api/system';
import { getContentsOfType } from '@/api/content';
import { addZero, getReadableTime, getFilterDate, compareAndSwitchDates, getWeekday, getMonth } from '@/utils/appointment-helpers';
import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, getStructuredFilters, getBasicFilters, argsFilterMapToJSMap, isset, changeRouteQuery } from '@/utils/helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import VueScrollTo from 'vue-scrollto';

export default {
    name: "Angebote",
    mixins: [screenSizeMixin],
    components: {
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        TitleBar: () => import('@/components/controls/TitleBar/TitleBar.vue'),
        WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
        MainCategorySelect: () => import('@/components/controls/MainCategorySelect.vue'),
        MainCategorySelectModal: () => import('@/components/modals/MainCategorySelectModal.vue'),
        StructuredMoreFilters: () => import('@/components/modals/StructuredMoreFilters/StructuredMoreFilters.vue'),
        KeywordSearch: () => import('@/components/controls/KeywordSearch'),
        TimePicker: () => import('@/components/controls/TimePicker'),
        CustomDatePicker: () => import('@/components/controls/CustomDatePicker'),
        Pagination: () => import('@/components/controls/Pagination'),
        ToggleSwitch: () => import('@/components/controls/ToggleSwitch')
    },
    data() {
        return {
            tempShowHide: true,
            showMainCategorySelectModal: false,
            currentContentTypeId: this.$offerId,
            tempContentCount: 0,
            contentType: null,
            sublocalities: null,
            isMobileView: false,
            searching: true,
            dataSet: {
                total: 0,
                data: [],
                meta: null,
                query: {
                    page: 1,
                    type: "teaser",
                    limit: 12,
                    meilisearch: '',
                    filters: null,
                    selectedEndDate: null,
                    selectedStartDate: null,
                    selectedStartTime: null,
                    selectedEndTime: null,
                    selectedContentTypeId: this.currentContentTypeId,
                    view_status: 1
                },
            },
            selectedCategory: null,
            selectedFilters: new Map(),
            expandFilter: false,
            filterModal: false,
            dateTimeSelection: {
                selectedEndDate: null,
                selectedStartDate: null,
                selectedStartTime: null,
                selectedEndTime: null,
            }
        }
    },
    created() {
        this.getOfferSettings();
        var filters = argsFilterMapToJSMap(this.uriFilters);
        if (isset(filters)) {
            this.filteringFinished(filters);
            this.showMainCategorySelectModal = false;
        }
        else {
            this.showMainCategorySelectModal = true;
        }
    },
    watch: {
        $screenWidth(newWidth) {
            if (newWidth <= 991) {
                this.isMobileView = true;
            }
            else {
                this.isMobileView = false;
            }
        },
        isMobileView(newValue, oldValue) {
            /*resetting the filters if the view changes from mobile to desktop*/
            if (newValue !== oldValue) {
                this.selectedFilters = new Map();
            }
        }
    },
    computed: {
        uriFilters() {
            return this.$route.query.filters;
        },
        showMap() {
            if (this.$isDesktopScreen) {
                return true;
            }

            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'show')) {
                return false;
            }
            return true;
        },
        structuredFilters() {
            return getStructuredFilters(this.contentType);
        },
        basicFilters() {
            var filters = getBasicFilters(this.structuredFilters);

            if (this.sublocalityFilter !== null) {
                filters.push(this.sublocalityFilter);
            }
            return filters;
        },
        allFilters() {
            return this.structuredFilters.concat(this.sublocalityFilter);
        },
        sublocalityFilter() {
            if (this.sublocalities !== null) {
                var bla = {};
                bla.field_type_id = "location";
                bla.label = "Stadtteile";
                bla.options = []
                for (var i = 0; i < this.sublocalities.length; i++) {
                    bla.options.push({ label: this.sublocalities[i].sublocality, value: this.sublocalities[i].sublocality });
                }
                return bla;
            }
            return null;
        },
        mainBasicFilter() {
            if (this.basicFilters !== null && this.basicFilters.length > 0) {
                var result = this.basicFilters.splice(0, 1);
                return result[0];
            }
            return null;
        },
        readableStartDate() {
            if (this.dateTimeSelection.selectedStartDate != null) {
                let d = this.dateTimeSelection.selectedStartDate === null ? new Date() : new Date(this.dateTimeSelection.selectedStartDate);
                let day = getWeekday(d.getDay()).substring(0, 3);
                let month = getMonth(d.getMonth() + 1).substring(0, 3);
                return day + ', ' + d.getDate() + '.' + ' ' + month + ' ' + d.getFullYear();
            }
            return '';
        },

    },
    methods: {
        showLoader,
        hideLoader,
        getSublocalities,
        createSelectOptions,
        getAllowedValuesFromFieldType,
        changePage(page) {
            this.dataSet.query.page = page;
            this.getContents();
        },
        setDateQuery() {
            this.dataSet.query.page = 1;
            this.dataSet.query.selectedStartDate = getFilterDate(this.dateTimeSelection.selectedStartDate);
            this.dataSet.query.selectedEndDate = getFilterDate(this.dateTimeSelection.selectedEndDate);
            this.dataSet.query.selectedStartTime = getReadableTime(this.dateTimeSelection.selectedStartTime);
            this.dataSet.query.selectedEndTime = getReadableTime(this.dateTimeSelection.selectedEndTime);
            this.getContents();
        },
        timeFilterChanged(timeDateObject) {
            this.dateTimeSelection = timeDateObject;
            this.setDateQuery();
        },
        startDateChanged(dateValue) {
            this.dateTimeSelection.selectedStartDate = dateValue;
            var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartDate, this.dateTimeSelection.selectedEndDate);
            this.dateTimeSelection.selectedStartDate = resObject.start;
            this.dateTimeSelection.selectedEndDate = resObject.end;
            this.setDateQuery();
        },
        endDateChanged(dateValue) {
            this.dateTimeSelection.selectedEndDate = dateValue;
            var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartDate, this.dateTimeSelection.selectedEndDate);
            this.dateTimeSelection.selectedStartDate = resObject.start;
            this.dateTimeSelection.selectedEndDate = resObject.end;
            this.setDateQuery();
        },
        startTimeChanged(timeValue) {
            this.dateTimeSelection.selectedStartTime = timeValue;
            var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartTime, this.dateTimeSelection.selectedEndTime);
            this.dateTimeSelection.selectedStartTime = resObject.start;
            this.dateTimeSelection.selectedEndTime = resObject.end;
            this.setDateQuery();
        },
        endTimeChanged(timeValue) {
            this.dateTimeSelection.selectedEndTime = timeValue;
            var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartTime, this.dateTimeSelection.selectedEndTime);
            this.dateTimeSelection.selectedStartTime = resObject.start;
            this.dateTimeSelection.selectedEndTime = resObject.end;
            this.setDateQuery();
        },
        filterAndFocusChanged(fieldTypeId, values) {
            this.filterChanged(fieldTypeId, values);
            setTimeout(() => {
                this.$refs.searchBox.focus();
            }, 2000);
        },
        filterChanged(fieldTypeId, values) {
            this.expandFilter = false;
            this.dataSet.query.page = 1;
            this.selectedFilters.set(fieldTypeId, values);
            this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
            this.getContents();
            this.expandFilter = true;
        },
        sliderChanged(fieldTypeId, value) {
            this.expandFilter = false;
            this.dataSet.query.page = 1;

            if (value) {
                this.selectedFilters.set(fieldTypeId, ["Kostenlos"]);
            }
            else {
                this.selectedFilters.delete(fieldTypeId);
            }
            this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
            this.getContents();
            this.expandFilter = true;
        },
        getOfferSettings(val) {
            this.dataSet.query.selectedContentTypeId = this.currentContentTypeId;
            this.$scrollTo();
            this.getContentType();
            this.getTheSublocalities();
        },
        searchWordChanged(word) {
            this.dataSet.query.page = 1;
            this.dataSet.query.meilisearch = word;
            this.getContents();
        },
        getNumberOfResults(map) {
            /*tempFilters will contain all the filters set for now but only for the purpose of calculating the number of items found for these filtersettings*/
            var tempFilters = new Map([...this.selectedFilters, ...map]);
            /*#todo: we'll have to start a call to the backend to find out how many values there are for this selection*/
            this.getNumberOfContentsMatchingTheFilter(tempFilters);
        },
        filteringFinished(map) {
            /*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
            this.dataSet.query.page = 1;
            this.expandFilter = false;
            this.selectedFilters = map;
            this.filterModal = false;
            this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
            this.getContents();
            this.expandFilter = true;
        },
        resetAllFilters() {
            this.tempShowHide = false;
            if (this.mainBasicFilter !== null && this.mainBasicFilter.hasOwnProperty('field_type_id')) {
                var res = this.selectedFilters.get(this.mainBasicFilter.field_type_id);
                this.selectedFilters = new Map();
                if (res !== null && res !== undefined) {
                    this.selectedFilters.set(this.mainBasicFilter.field_type_id, res);
                }
            }
            else {
                this.selectedFilters = new Map();
            }
            this.filteringFinished(this.selectedFilters);
        },
        updateOnClose() {
            this.filterModal = false;
        },
        getContentType() {
            this.contentType = null;
            var id = this.currentContentTypeId;
            this.loader = this.showLoader(this.loader);
            /*overwrite the existing filters*/
            this.selectedFilters = new Map();
            contentTypeResource.get(id).then(response => {
                this.contentType = response.data;
            })
                .finally(() => {
                    this.loader = this.hideLoader(this.loader);
                });
        },
        async getTheSublocalities() {
            var id = this.currentContentTypeId;
            getSublocalities(this.$city, null, id).then(response => {
                this.sublocalities = response;
            });
        },
        getContents() {
            /*test to see if that influences the back-button*/
            /*könnte man auch für alle Filter machen - dann müsste man da aber wieder eine Funktion schreiben die das Dingens zurück verwandelt in ein Objekt das aussieht wie der Query ...
            changeRouteQuery(this.$route, this.dataSet.query.filters);*/
            history.pushState({}, null, this.$route.path + '?filters=' + encodeURIComponent(this.dataSet.query.filters));
            this.searching = true;
            this.dataSet.query.type = "view";
            var contentLoader = this.showLoader(contentLoader);
            const { limit, page } = this.dataSet.query;
            getContentsOfType(this.currentContentTypeId, this.dataSet.query).then(response => {
                const { data, meta } = response;
                this.dataSet.data = data;
                this.dataSet.data.forEach((element, index) => {
                    element['index'] = (page - 1) * limit + index + 1;
                });
                this.dataSet.meta = meta;
                this.dataSet.total = meta.total;
                this.tempContentCount = meta.total;
            })
                .finally(() => {
                    contentLoader = this.hideLoader(contentLoader);
                    document.getElementById('main-scroll-container').scroll({ top: 0, behavior: 'smooth' });
                    this.tempShowHide = true;
                    this.searching = false;
                });
        },
        getNumberOfContentsMatchingTheFilter(tempFilters) {
            this.dataSet.query.type = "count";
            this.dataSet.query.filters = JSON.stringify(Array.from(tempFilters.entries()));
            var contentLoader = this.showLoader(contentLoader);
            getContentsOfType(this.currentContentTypeId, this.dataSet.query).then(response => {
                this.tempContentCount = response;
            })
                .finally(() => {
                    contentLoader = this.hideLoader(contentLoader);
                });
        },
    }
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.search-no-results {
    margin-top: 50px;
    width: 100%;
    text-align: center;

    img {
        width: 150px;
    }
}

.main-sidebar__content {
    min-height: 930px;
}

.offer-row {
    .col-xls-6 {
        @media(max-width: 1450px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .col-lgs-6 {
        @media(max-width: 1150px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .col-md-6 {
        @media(max-width: 991px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .col-12 {
        @media(max-width: 700px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
</style>
